.blogCard {
 width: 100%;
 max-width: 300px;
 min-width: 200px;
 padding: 10px;
}

.blogCategory {
 width: 100%;
 background-color: #ededed;
 color: orange;
 font-weight: bolder;
 border-radius: 10px;
 padding: 10px;
 font-size: 13px;
}

.blogTitle {
 width: 100%;
 text-align: justify;
 font-weight: bolder;
 font-size: 18px;
}

.blogReadMore {
 width: 100%;
 border: 2px solid;
 text-align: center;
 color: orange;
 font-weight: bolder;
 padding: 6px;
 border-radius: 5px;
 cursor: pointer;
}

.blogReadMore:hover {
 color: #737373;
 transition: all 0.2s linear;
}
