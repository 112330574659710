.ContactUsContainer {
 width: 90%;
 margin: 110px auto 40px auto;
 display: flex;
 justify-content: center;
 gap: 30px;
 flex-wrap: wrap;
}

.contactInfoContainer {
 box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
  rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
 border-radius: 10px;
 width: 100%;
 min-width: 250px;
 max-width: 500px;
}

.checkOutTitles {
 background-color: orange;
 border-radius: 10px 10px 0 0;
 padding: 10px;
 color: white;
}

.contactUsIconsContainer {
 width: 90%;
 margin: 30px auto;
 display: flex;
 justify-content: space-evenly;
 align-items: center;
}

.contactUsIcon {
 color: #737373;
 cursor: pointer;
}

.contactUsIcon:hover {
 transform: scale(1.2);
 transition: 0.3s all ease-out;
}

.QueryFormContainer {
 box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
  rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
 border-radius: 10px;
 width: 100%;
 min-width: 250px;
 max-width: 500px;
}
