.checkoutContainer {
 width: 90%;
 margin: auto;
 padding: 20px;
 background-color: white;
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 gap: 30px;
 background-color: rgb(230, 230, 230);
}

.basicDetailsContainer {
 max-width: 700px;
 min-width: 300px;
 width: 100%;
 background-color: white;
 border-radius: 10px;
}

.checkOutTitles {
 background-color: orange;
 border-radius: 10px 10px 0 0;
 padding: 10px;
 color: white;
}

.orderSummaryContainer {
 max-width: 400px;
 min-width: 300px;
 width: 100%;
 background-color: white;
 border-radius: 10px;
}

.checkoutLoadingOverlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 9999;
}

.checkoutSpinner {
 border: 12px solid #737373;
 border-top: 12px solid orange;
 border-radius: 50%;
 width: 80px;
 height: 80px;
 animation: spin 1.2s linear infinite;
}

@keyframes spin {
 0% {
  transform: rotate(0deg);
 }
 100% {
  transform: rotate(360deg);
 }
}

.trustBatchesContainer {
 width: 90%;
 margin: auto;
 background-color: white;
 border-radius: 10px;
 padding: 20px;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 20px;
}

@media screen and (max-width: 900px) {
 .trustBatchesContainer {
  flex-direction: column;
 }
}

.trustBatchesContainer > div {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10px;
 width: 100%;
 max-width: 170px;
}

.trustBatchesContainer > p {
 text-align: justify;
 margin-bottom: 0;
}
