.basicInfoInput {
 min-width: 250px;
 margin: 10px;
}

.basicInfoContactNumContainer {
 display: flex;
 justify-content: center;
 align-items: center;
}

@media screen and (max-width: 500px) {
 .basicInfoContactNumContainer {
  flex-direction: column;
 }
}

.basicInfoProceedBtn {
 padding: 10px 40px;
 font-weight: bold;
 letter-spacing: 1px;
 cursor: pointer;
 color: white;
 background-color: orange;
 border-radius: 10px;
 transition: 0.25s all;
 border: 2px solid orange;
}

.basicInfoProceedBtn:hover {
 background-color: white;
 color: orange;
}

@media screen and (max-width: 900px) {
 .basicInfoProceedBtn {
  padding: 8px 20px;
  font-size: 15px;
 }
}

@media screen and (max-width: 400px) {
 .basicInfoProceedBtn {
  padding: 6px 15px;
 }
}
