.AllCourses {
 width: 100%;
 height: auto;
 margin-bottom: 2rem;
}

.AllCourses > div {
 width: 90%;
 height: auto;
 margin: auto;
}

.AllCourses > div > p {
 font-size: 1.5rem;
 font-family: 'Lato', sans-serif;
 padding: 2rem 0rem;
}

.AllCoursesDiv {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: baseline;
}

.AllCoursesDiv > aside {
 width: 280px;
 height: 100%;
 box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 font-family: 'Lato', sans-serif;
 border-radius: 10px;
 margin-right: 20px;
}

.AllCoursesDiv > aside > p {
 color: orange;
 margin: 0.75rem 0.5rem;
 padding: 0.2rem;
 font-size: 15px;
 font-weight: bolder;
}

.AllCoursesDiv > aside > ul {
 list-style: none;
 margin-bottom: 10px;
}

.AllCoursesDiv > aside > ul div {
 margin: 0rem 0.5rem 0 -1rem;
 border-radius: 5px;
 padding: 0.25rem 0.4rem;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.AllCoursesDiv > aside > ul div.activeCtgr {
 background-color: rgb(255, 242, 232);
}

.AllCoursesDiv > aside > ul div:hover {
 background-color: rgb(255, 242, 232);
 cursor: pointer;
}

.AllCoursesDiv > aside > ul li {
 font-size: 15px;
 line-height: 30px;
}

.AllCoursesDiv > main {
 width: auto;
 height: auto;
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-template-rows: repeat(auto);
 gap: 20px;
}

@media screen and (max-width: 1200px) {
 .AllCoursesDiv > main {
  grid-template-columns: repeat(2, 1fr);
 }
}

@media screen and (max-width: 880px) {
 .AllCoursesDiv > aside {
  display: none;
 }
}
@media screen and (max-width: 630px) {
 .AllCoursesDiv > aside {
  display: none;
 }
 .AllCoursesDiv > main {
  grid-template-columns: repeat(1, 1fr);
 }
}
