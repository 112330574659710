.navHeader {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
 z-index: 999;
 background-color: white;
}

.navbar {
 width: 90vw;
 margin: auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.smartranxLogo > span {
 display: flex;
 justify-content: center;
 align-items: center;
 text-transform: uppercase;
 height: 70px;
}

.smartranxLogo > span p {
 font-weight: bolder;
 padding-left: 10px;
}

.smartranxLogo p:nth-child(1) {
 color: orange;
 font-size: 25px;
 letter-spacing: 2px;
 margin-bottom: -5px;
}

.smartranxLogo p:nth-child(2) {
 color: rgb(78, 78, 78);
 font-size: 12px;
 letter-spacing: 2px;
 margin-bottom: -5px;
}

.smartranxLogo > span p > span {
 font-size: 20px;
}

.navbarLinks {
 width: 60%;
 margin: 0;
 padding: 0;
 list-style: none;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.navItem {
 text-decoration: none;
 color: #737373;
 font-weight: bolder;
 letter-spacing: 1.5px;
}

.navItem:hover {
 color: orange;
}

.navbarItem {
 display: inline;
}

.navbarMenuIcon {
 display: none;
 cursor: pointer;
}

@media (max-width: 700px) {
 .navbarLinks {
  display: none;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #333;
 }

 .navbarLinks.open {
  display: flex;
 }

 .navbarMenuIcon {
  display: block;
 }
}

.navItemsVertical {
 width: 100%;
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-direction: column;
}

.navItemVertical {
 padding-left: 30px;
 line-height: 40px;
 border-bottom: 1px solid #eaeaea;
 width: 100%;
}

.navItemVertical:first-child {
 border-top: 1px solid #eaeaea;
}

.navItemVertical:hover {
 background-color: #eaeaea;
 transition: all 0.3s ease-in-out;
}
