.mid {
 border-top: 5px solid orange;
}

.iconList {
 padding-left: 0px;
 list-style-type: none;
 margin-bottom: 40px;
}

.iconList li {
 margin-bottom: 10px;
 display: flex;
 align-items: center;
 font-size: 13px;
}

.bottom {
 /* border: 2px solid red; */
 width: 100%;
 height: 60px;
 padding: 15px;
 position: absolute;
 bottom: 0;
 left: 0;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.cardKnowMoreBtn {
 color: orange;
 font-weight: bold;
 text-decoration: none;
}

.cardKnowMoreBtn:hover {
 color: rgb(230, 112, 112);
 transform: scale(1.05);
}
