@keyframes slideAnimation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slideshowContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.slideshowImages {
  display: flex;
  animation: slideAnimation 20s linear infinite;
}

.slideshowImage {
  margin: 0rem 2rem;
  height: 70px;
  width: auto;
}
