.courseDetailsContainer {
 width: 90%;
 margin: 10px auto;
}

.promoInput {
 width: 70%;
 border: 2px solid #737373;
 border-radius: 5px;
}

.promoButton {
 background-color: rgb(255, 220, 154);
 width: 70px;
 border: 2px solid orange;
 border-radius: 5px;
}

.qtyContainer {
 /* width: 100px; */
 /* height: 50px; */
 /* border: 1px solid; */
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.qtyContainer > button {
 padding: 3px 6px;
 /* margin: 0 10px; */
 font-weight: bolder;
 font-size: 15px;
 border: 3px solid orange;
 background-color: rgb(255, 242, 232);
 cursor: pointer;
 border-radius: 5px;
}
