.scheduleTopContainer {
 display: flex;
 justify-content: space-around;
 align-items: center;
 flex-wrap: wrap;
 gap: 20px;
}

.scheduleTopContainer > div:nth-child(2) {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.scheduleTopContainer > div:last-child {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.scheduleKeyFeaturesContainer {
 padding: 0 20px;
 margin-bottom: 20px;
}

.scheduleKnowMoreContainer {
 display: flex;
 justify-content: center;
 margin-top: 20px;
}

.scheduleEnrollButton {
 padding: 8px 12px;
 font-size: 20px;
 font-weight: bold;
 letter-spacing: 1.25px;
 cursor: pointer;
 color: white;
 background-color: orange;
 border: none;
 border-radius: 10px;
 transition: 0.25s all;
 border: 2px solid orange;
}

.scheduleEnrollButton:hover {
 background-color: white;
 color: orange;
}

.scheduleButton {
 padding: 2px 10px;
 font-size: 15px;
 font-weight: bold;
 letter-spacing: 1.25px;
 cursor: pointer;
 color: white;
 background-color: rgb(41, 113, 229);
 border: none;
 border-radius: 10px;
 transition: 0.25s all;
 border: 2px solid rgb(41, 113, 229);
}

.scheduleButton:hover {
 background-color: white;
 color: rgb(41, 113, 229);
}

.scheduleTopLine {
 width: 100%;
 height: 3px;
 background-color: #737373;
 position: absolute;
 top: 0;
 left: 0;
 border-top-left-radius: 10px;
 border-top-right-radius: 10px;
}

.scheduleBottomLine {
 width: 100%;
 height: 3px;
 background-color: orange;
 position: absolute;
 bottom: 0;
 left: 0;
 border-bottom-left-radius: 10px;
 border-bottom-right-radius: 10px;
}
