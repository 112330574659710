.PartnerImagesDiv {
  width: 90%;
  height: auto;
  margin: auto;
  text-align: center;
  padding: 1.5rem 0;
}

.PartnerImagesDiv > p {
  font-size: 1rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
  letter-spacing: 0.075rem;
}

@media screen and (max-width: 550px) {
  .PartnerImagesDiv > p {
    font-size: 0.75rem;
  }
}
