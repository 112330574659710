.footer {
 width: 100%;
 height: auto;
 background-color: black;
 color: white;
 padding-top: 25px;
 margin-top: 70px;
}

.top {
 width: 90%;
 height: auto;
 margin: auto;
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 gap: 20px;
}

@media screen and (max-width: 700px) {
 .top {
  grid-template-columns: repeat(2, 1fr);
 }
}
@media screen and (max-width: 500px) {
 .top {
  grid-template-columns: repeat(1, 1fr);
 }
}

.columns {
 height: auto;
}

.title {
 color: orange;
 font-weight: bolder;
 font-size: 15px;
 margin-bottom: 15px;
}

.list,
.listLink {
 margin: 10px 0;
 font-size: 13px;
 text-decoration: none;
 color: white;
}

.list:hover,
.listLink:hover {
 color: orange;
 padding-left: 5px;
 transition: 0.5s all linear;
 cursor: pointer;
}

.socialIcons {
 width: 170px;
 height: auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.icon {
 font-size: 25px;
 color: white;
}

.icon:hover {
 color: orange;
 cursor: pointer;
 transform: scale(1.2);
 transition: 0.5s all;
}

.categories {
 width: 90%;
 margin: auto;
 padding: 10px 0;
}

.trendingCourses {
 width: 90%;
 margin: auto;
 padding: 20px 0;
}

.disclaimer {
 width: 90%;
 margin: auto;
 padding: 20px 0;
}

.disclaimer li {
 list-style: none;
 margin-bottom: 5px;
 font-size: 13px;
}

.copyrights {
 width: 100%;
 height: auto;
 padding: 10px;
 background: rgb(65, 54, 33);
 display: flex;
 justify-content: center;
 align-items: center;
}
