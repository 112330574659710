.BlogsContainer {
 margin: 100px auto 30px auto;
 width: 90%;
 display: flex;
 gap: 20px;
}

.blogsAsideContainer {
 width: 100%;
 min-width: 200px;
 max-width: 300px;
 height: 100%;
 box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 font-family: 'Lato', sans-serif;
 border-radius: 10px;
 margin-right: 20px;
}

@media screen and (max-width: 650px) {
 .BlogsContainer {
  flex-direction: column;
 }
}

.blogsAsideContainer > p {
 color: orange;
 margin: 0.75rem 0.5rem;
 padding: 0.2rem;
 font-size: 15px;
 font-weight: bolder;
}

.blogsAsideContainer > ul {
 list-style: none;
 margin-bottom: 10px;
}

.blogsAsideContainer > ul div {
 margin: 0rem 0.5rem 0 -1rem;
 border-radius: 5px;
 padding: 0.25rem 0.4rem;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.blogsAsideContainer > ul div:hover {
 background-color: rgb(255, 242, 232);
 cursor: pointer;
}

.blogsAsideContainer > ul div.activeBlog {
 background-color: rgb(255, 242, 232);
}

.blogsAsideContainer > ul li {
 font-size: 15px;
 line-height: 30px;
}

.blogsMainContainer {
 width: 100%;
 display: flex;
 gap: 20px;
 flex-wrap: wrap;
}
