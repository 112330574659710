.banner {
  min-height: 100vh;
  background: linear-gradient(rgba(250, 147, 147, 0.5), rgba(0, 0, 0, 0.171)),
    url(../../public/images/HomePage.jpg) center/cover no-repeat;
  background-position-y: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerIn {
  width: 90vw;
  height: auto;
  margin: auto;
  padding-top: 2rem;
}

.bannerIn h1 {
  font-size: 4rem;
  color: white;
  letter-spacing: 0.15rem;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.bannerIn h1:nth-child(2) {
  margin-bottom: 1.75rem;
}

.bannerNumText {
  font-size: 25px;
  font-weight: 900;
  color: orange;
  -webkit-text-stroke: 0.3px black;
}

.bannerIn p {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

.bannerBtn {
  margin-top: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.9rem 1.6rem;
  font-size: 1.25rem;
  background: orange;
  color: black;
  letter-spacing: 0.15rem;
  display: inline-block;
  transition: all 0.3s linear;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: bolder;
  border-radius: 10px;
}

.bannerBtn:hover {
  background: transparent;
  color: white;
  border-color: orange;
}

@media screen and (max-width: 880px) {
  .bannerIn h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .bannerIn p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .bannerBtn {
    padding: 0.7rem 1.2rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
}

@media screen and (min-width: 881px) {
  .bannerIn h1 {
    font-size: 2.75rem;
  }
  .bannerIn p {
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
  .bannerBtn {
    padding: 0.7rem 1.2rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
}
